import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useIdentity } from 'pages/Identity/Login'
import { Grid, Typography } from '@mui/material'
import { List } from 'components/Listing/List'
import Loader from 'components/Loader'
import { getCandidateJobs, getHospitalJobs } from 'data/jobs/actions'
import { fetchJobFilterValues } from 'src/redux/filters'
import JobContent from './JobContent'
import { Filters } from '../../components/Filters'
import { FilterType } from '../../components/Filters/filterDefinitions'
import JobsPageTutorial from './JobsPageTutorial'
import { applyFilters } from 'components/Filters/helpers'
import RecruiterJobPageTutorial from './RecruiterJobPageTutorial'
import { candidateTypeDefinitions } from 'components/Filters/candidateTypeDefinitions'
import { filterDefinitions } from 'components/Filters/filterDefinitions'
import { fetchUpdateCandidateJob } from 'src/redux/candidateJob'

const Jobs = () => {
  const dispatch = useDispatch()
  const signedIn = useSelector(state => state.identity.signedIn)
  const {
    candidateType,
    specialties = [],
    loading: candidateLoading,
    tutorialViews: candidateTutorialViews = [],
  } = useSelector(state => state.candidate)
  const { tutorialViews: employerUserTutorialViews = [], loading: employerUserLoading } =
    useSelector(state => state.employerUser)
  const loading = candidateLoading || employerUserLoading
  const { employerId, employerUserId, candidateId, userId, isEmployerAdmin } = useIdentity()
  const [activeItem, setActiveItem] = useState()
  const [page, setPage] = useState(0)
  const pageSize = 50
  const { jobsList = [], isLoadingJobs } = useSelector(state => state.jobs)
  const { isLoadingFilters } = useSelector(state => state.filters).loading
  const filterValues = useSelector(state => state.filters.jobFilterValues) || {}
  const totalJobs = useSelector(state => state.jobs.totalJobs)
  const totalPages = Math.ceil(totalJobs / pageSize)
  const dataCall = employerUserId ? getHospitalJobs : getCandidateJobs
  const [searchParams, setSearchParams] = useSearchParams()
  const candidateTypeId = searchParams.get('candidateTypeId')
  const [displayedData, setDisplayedData] = useState(jobsList)
  const recruiterJobTutorialName = 'RecruiterJobIntro'
  const candidateJobTutorialName = 'CandidateJobIntro'
  const hasSeenRecruiterJobTutorial = employerUserTutorialViews.includes(recruiterJobTutorialName)
  const hasSeenCandidateJobTutorial = candidateTutorialViews.includes(candidateJobTutorialName)

  const handleListItemClick = currId => {
    const activeJob = !isNil(currId)
      ? displayedData.find(j => `${j.id}` === `${currId}`)
      : displayedData.length > 0
        ? displayedData[0]
        : null
    setActiveItem(activeJob)
  }

  useEffect(() => {
    if (!signedIn) {
      dispatch(dataCall({ page, pageSize }))
    }
  }, [signedIn, dispatch, dataCall, page, pageSize])

  useEffect(() => {
    setActiveItem(jobsList[0])
  }, [jobsList])

  useEffect(() => {
    if (candidateType && candidateType.id && searchParams.size === 0) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('candidateTypeId', candidateType.id)
      const candidateTypeDefinition = candidateTypeDefinitions.find(
        def => def.id === candidateType.id,
      )
      const specialtyFilterDefinition = filterDefinitions.find(
        filter => filter.name === 'specialty',
      )
      if (candidateTypeDefinition?.useCandidateSpecialty === true) {
        if (specialties.length > 0) {
          newParams.set(specialtyFilterDefinition.queryStringKey, specialties[0].id)
        } else {
          newParams.delete(specialtyFilterDefinition.queryStringKey)
        }
      } else {
        newParams.delete(specialtyFilterDefinition.queryStringKey)
      }
      setSearchParams(newParams)
    }
  }, [candidateType, specialties])

  useEffect(() => {
    if (candidateTypeId) {
      const specialtyIdsParam = searchParams.get('specialtyIds')
      dispatch(
        fetchJobFilterValues({
          employerId: employerId,
          candidateTypeId: searchParams.get('candidateTypeId'),
          specialtyIds: specialtyIdsParam ? specialtyIdsParam.split('|') : [],
        }),
      )
    }
  }, [candidateTypeId])

  useEffect(() => {
    const specialtyIdsParam = searchParams.get('specialtyIds')
    dispatch(
      fetchJobFilterValues({
        employerId: employerId,
        candidateTypeId: searchParams.get('candidateTypeId'),
        specialtyIds: specialtyIdsParam ? specialtyIdsParam.split('|') : [],
      }),
    )
    setDisplayedData(jobsList)
    if (activeItem) {
      const updatedActiveItem = jobsList.find(job => job.id === activeItem.id)
      if (updatedActiveItem) {
        setActiveItem(updatedActiveItem)
      } else {
        setActiveItem(jobsList[0])
      }
    } else {
      setActiveItem(jobsList[0])
    }
  }, [jobsList, searchParams])

  const leftPanelHeader = 'Results: ' + `${totalJobs ? totalJobs.toLocaleString() : 0}` + ' Jobs'

  const applyJobFilters = ({ searchParams }) => {
    applyFilters({
      candidateId,
      employerUserId,
      searchParams,
      dispatch,
      getData: dataCall,
      page,
      pageSize,
      employerId,
      isEmployerAdmin,
      userId,
    })
  }

  // TODO: In the future, we should probably not optimistically update the front end, but update the job based on the response without a full page reload
  const onFavoriteClick = ({ favorite, jobId }) => {
    const updatedData = displayedData.map(item =>
      item.id === jobId
        ? {
            ...item,
            candidateJob: {
              ...item.candidateJob,
              favorite,
            },
          }
        : item,
    )

    setDisplayedData(updatedData)

    // Update the active item if it matches the jobId
    if (activeItem && activeItem.id === jobId) {
      const updatedActiveItem = {
        ...activeItem,
        candidateJob: {
          ...activeItem.candidateJob,
          favorite,
        },
      }
      setActiveItem(updatedActiveItem)
    }

    // Dispatch the update action to the backend
    dispatch(fetchUpdateCandidateJob({ candidateId, jobId, favorite }))
  }

  return (
    <>
      <Grid container justifyContent='center'>
        {!employerUserId && signedIn && !hasSeenCandidateJobTutorial && !loading && (
          <JobsPageTutorial />
        )}
        {employerUserId && signedIn && !hasSeenRecruiterJobTutorial && !loading && (
          <RecruiterJobPageTutorial totalJobs={totalJobs} />
        )}
        <Grid item xs={12}>
          <Filters
            filterType={FilterType.JOB}
            filterValues={filterValues}
            listType='job'
            loading={isLoadingFilters}
            applyFilters={applyJobFilters}
          />
        </Grid>
        {isLoadingJobs || loading ? (
          <Loader />
        ) : (
          <Grid container sx={{ maxWidth: '70em' }}>
            <Grid
              container
              item
              sm={12}
              md={5}
              direction='column'
              alignContent='flex-start'
              sx={{ height: '100%' }}
            >
              <Grid item>
                <Typography variant='h6' sx={{ padding: '10px 16px' }}>
                  {leftPanelHeader}
                </Typography>
              </Grid>
              <div className='joyride_job_list'>
                <Grid
                  container
                  alignItems='flex-start'
                  sx={{ maxHeight: '100vh', overflow: 'auto' }}
                >
                  <List
                    setPage={setPage}
                    page={page}
                    data={displayedData}
                    listType='job'
                    handleListItemClick={handleListItemClick}
                    dataTotal={totalJobs}
                    totalPages={totalPages}
                    signedIn={signedIn}
                    setDisplayedData={setDisplayedData}
                    onFavoriteClick={onFavoriteClick}
                    setActiveItem={setActiveItem}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid
              container
              item
              md={7}
              direction='column'
              sx={{ maxHeight: '100vh', overflow: 'auto' }}
            >
              <JobContent
                item={activeItem}
                signedIn={signedIn}
                employerUserId={employerUserId}
                onFavoriteClick={onFavoriteClick}
                jobs={jobsList}
                activeItem={activeItem}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Jobs
