import React from 'react'
import { LocationCity, Home, Terrain, CheckCircle, Cancel, SwapHoriz } from '@mui/icons-material' // Import relevant icons
import {
  PRACTICE_TYPE_ENUM,
  AREA_ENUM,
  ACADEMIC_ENUM,
  VISA_ENUM,
  SHIFT_TYPE_ENUM,
  SALARY_TYPE_ENUM,
} from 'data/enums'
import { States } from 'data/constants'

export const AREA_ICONS = {
  Urban: <LocationCity />, // Urban icon
  Suburban: <Home />, // Suburban icon
  Rural: <Terrain />, // Rural icon
}

export const ACADEMIC_ICONS = {
  Yes: <CheckCircle />, // Represents "Yes" with a checkmark icon
  Hybrid: <SwapHoriz />, // Represents "Hybrid" with a swap/transition icon
  No: <Cancel />, // Represents "No" with a cancel icon
}

export const practiceTypeOptions = Object.entries(PRACTICE_TYPE_ENUM).map(([id, name]) => ({
  id,
  name,
}))
export const salaryTypeOptions = Object.entries(SALARY_TYPE_ENUM).map(([id, name]) => ({
  id,
  name,
}))
export const visaOptions = Object.entries(VISA_ENUM).map(([id, name]) => ({
  id,
  name,
}))
export const yesNoOptions = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
]
export const shiftTypeOptions = Object.entries(SHIFT_TYPE_ENUM).map(([id, name]) => ({
  id,
  name,
}))
export const stateOptions = Object.entries(States).map(([id, name]) => ({
  id,
  name,
}))
export const areaOptions = Object.entries(AREA_ENUM).map(([id, name]) => ({
  id,
  name,
}))
export const academicOptions = Object.entries(ACADEMIC_ENUM).map(([id, name]) => ({
  id,
  name,
}))

export const academicTooltipText = `
    Yes: Implies it has an associated university.
    Hybrid: Includes residencies.
    No: Regular physician jobs, community, private practice, group.
  `
