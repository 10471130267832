import React, {useEffect} from 'react'
import Header from 'components/Layout/Header'
import Container from '@mui/material/Container'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useContentHeight } from 'data/hooks'

const useStyles = makeStyles(({ layout, breakpoints }) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    main: {
      flexGrow: 1,
      [breakpoints.down('xs')]: {
        maxWidth: '100vw',
      },
    },
    content: ({ height }) => ({
      height,
      overflowX: 'auto',
      overflowY: 'auto',
      padding: layout.contentPadding,
      backgroundColor: 'white',
    }),
  }),
)

export default function Layout({ children }) {
  const canonicalHref = `${window.location.origin}${window.location.pathname}`
  useEffect(() => {
    document.title = "MyStethi: Your free and easy platform for medical jobs."
    document.getElementById("canonicalHref").setAttribute("href", canonicalHref)
  }, [canonicalHref])

  const classes = useStyles({ height: useContentHeight() })
  return (
    <div data-testid='app-container' className={classes.root}>
      <main className={classes.main}>
        <Header />
        <Container maxWidth={false} className={classes.content}>
          {children}
        </Container>
      </main>
    </div>
  )
}
