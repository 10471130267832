import React, { useState } from 'react'
import { Grid, Typography, Link, Tooltip, Box } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const GenericFieldHeader = ({
  name,
  field,
  optional = false,
  handleReset,
  showClear,
  tooltipText,
}) => {
  // Manage tooltip open state for click-to-open behavior
  const [open, setOpen] = useState(false)

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <Grid container alignItems='center'>
      <Grid item>
        <Typography
          variant='subtitle1'
          sx={{
            fontStyle: 'italic',
            color: 'gray',
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {name} {optional ? ' (Optional)' : ''}
          {/* Render Tooltip and Icon only if tooltipText is provided */}
          {tooltipText && (
            <Tooltip
              title={tooltipText}
              arrow
              open={open}
              onClose={() => setOpen(false)}
              disableHoverListener
              disableFocusListener
              disableTouchListener
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 8], // Slightly larger hover area
                    },
                  },
                ],
              }}
            >
              <Box
                component='span'
                onClick={handleTooltipToggle}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  ml: 0.5,
                  cursor: 'pointer',
                  padding: '2px', // Increase clickable area
                  fontSize: '0.8em', // Smaller icon size
                  verticalAlign: 'super',
                }}
              >
                <InfoOutlinedIcon fontSize='inherit' color='action' />
              </Box>
            </Tooltip>
          )}
        </Typography>
      </Grid>
      {showClear && (
        <Grid item>
          <Link
            component='button'
            variant='body2'
            onClick={() => handleReset(field)}
            sx={{ marginLeft: 2, textDecoration: 'underline', cursor: 'pointer' }}
          >
            Clear
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

export default GenericFieldHeader
