import { BOARD_ELIGIBILITY_ENUM, SEARCH_STATUS_ENUM } from 'data/enums'
import { splitCamelCaseToString } from 'data/helpers'
import { States } from 'data/constants'
import { VISA_ENUM } from 'data/enums'

export const getBoardingEligibilityOptions = () => {
  const eligibilityOptions = Object.keys(BOARD_ELIGIBILITY_ENUM).map(key => ({
    id: BOARD_ELIGIBILITY_ENUM[key],
    label: splitCamelCaseToString(BOARD_ELIGIBILITY_ENUM[key]),
  }))
  return eligibilityOptions
}

export const getRequiresSponsorshipOptions = () => {
  const requiresSponsorship = [
    { id: 'No', label: 'No' },
    { id: 'Yes', label: 'Yes' },
  ]
  return requiresSponsorship
}

export const getLicensedStatesOptions = () => {
  return Object.keys(States).map(option => States[option])
}

export const getAvailabilityOptions = () => {
  const availabilityOptions = Object.keys(SEARCH_STATUS_ENUM).map(key => ({
    id: SEARCH_STATUS_ENUM[key],
    name: splitCamelCaseToString(SEARCH_STATUS_ENUM[key]),
  }))
  return availabilityOptions
}

export const getVisaOptions = () => {
  const visaOptions = Object.keys(VISA_ENUM).map((key, index) => ({
    id: index + 1,
    label: VISA_ENUM[key], // Use 'label' to match what GenericSelectField expects
  }))
  return visaOptions
}
