import * as React from 'react'
import { Grid, Button } from '@mui/material'
import DialogButton from 'components/Dialog/DialogButton'
import * as JobInterestDialog from 'components/Dialog/JobInterestDialog'
import * as RatingDialog from 'components/Dialog/RatingDialog'

export default function JobActionsButton({
  jobId,
  candidateRating,
  handleDrawerOpen,
  mobile = false,
}) {
  const openDrawer = () => {
    handleDrawerOpen(true)
  }

  return (
    <>
      <Grid item>
        <div className='joyride_job_interest'>
          <DialogButton
            Module={JobInterestDialog}
            size='small'
            tooltip=''
            variant='contained'
            jobId={jobId}
            interestedInContact={false}
            initialValues={{}}
            dialogId='Job Interest'
            variant='outlined'
          >
            I'm interested
          </DialogButton>
        </div>
      </Grid>
      <Grid item>
        <div className='joyride_rate_job'>
          <DialogButton
            Module={RatingDialog}
            size='small'
            tooltip=''
            jobId={jobId}
            variant='outlined'
            candidateRating={candidateRating}
            initialValues={{}}
          >
            Rate Accuracy
          </DialogButton>
        </div>
      </Grid>
      {!mobile && (
        <Grid item>
          <div className='joyride_add_job_notes'>
            <Button onClick={openDrawer} color='secondary' size='small' variant='outlined'>
              My Notes
            </Button>
          </div>
        </Grid>
      )}
    </>
  )
}
