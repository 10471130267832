import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GenericDrawer from '../GenericComponents/GenericDrawer'
import JobInformationSection from './JobInformationSection'
import CreateFilterAlert from './CreateFilterAlert'
import { getHospitalJobs, setSelectedFilters } from 'data/jobs/actions'
import Loader from 'components/Loader'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
const JobDrawer = ({
  open,
  onClose,
  onResponse,
  dialogId = 'JobDrawer',
  jobToEdit,
  isEdit = false,
  setOpenDrawer,
}) => {
  const dispatch = useDispatch()
  const { employerId, employerUserId, isEmployerAdmin } = useSelector(state => state.identity)
  const [jobAlertData, setJobAlertData] = useState({})
  const { loading: jobLoading } = useSelector(state => state.job)
  const { loading: filtersLoading } = useSelector(state => state.filters)
  const pageLoading = jobLoading || filtersLoading
  const [disableAlerts, setDisableAlerts] = useState(!isEdit)
  const [openJobInformation, setOpenJobInformation] = useState(true)
  const [openFilterAlert, setOpenFilterAlert] = useState(false)
  const [jobInformationTitle, setJobInformationTitle] = useState('Job Information')

  useEffect(() => {
    if (!open) {
      // Reset state to default values
      if (isEdit) {
        setDisableAlerts(false)
      } else {
        setDisableAlerts(true)
      }
      setOpenJobInformation(true)
      setOpenFilterAlert(false)
    } else {
      if (isEdit) {
        setDisableAlerts(false)
      } else {
        setDisableAlerts(true)
      }
    }
  }, [open])

  const handleSuccessfulSavedJob = () => {
    setOpenJobInformation(false)
    setDisableAlerts(false)
    setOpenFilterAlert(true)
    setJobInformationTitle(
      <span>
        Job Information Saved{' '}
        <CheckCircleIcon color='success' sx={{ fontSize: '1.1rem', ml: 0.5 }} />
      </span>,
    )
  }

  const handleSuccessfulCompletion = () => {
    const employerUserIds =
      employerUserId && !isEmployerAdmin ? { employerUserIds: [employerUserId] } : null
    dispatch(getHospitalJobs({ employerId: employerId, ...employerUserIds }))
    dispatch(setSelectedFilters([]))
    setOpenDrawer(false)
  }
  const userJobFilters = jobToEdit?.userFilters

  return (
    <GenericDrawer
      open={open}
      onClose={onClose}
      title={isEdit ? 'Edit Job' : 'Add New Job'}
      width='600px'
      showActionButtons={false}
    >
      {pageLoading ? (
        <Loader />
      ) : (
        <>
          <JobInformationSection
            jobToEdit={jobToEdit}
            isEdit={isEdit}
            onResponse={onResponse}
            dialogId={dialogId}
            onClose={onClose}
            handleSuccessfulSavedJob={handleSuccessfulSavedJob}
            isOpenByDefault={openJobInformation}
            setJobAlertData={setJobAlertData}
            handleSuccessfulJobEdit={handleSuccessfulCompletion}
            title={jobInformationTitle}
          />
          <CreateFilterAlert
            disabledMessage='You must first save the job to create an alert'
            onClose={onClose}
            handleSuccessfulCompletion={handleSuccessfulCompletion}
            disabled={disableAlerts}
            openFilterAlert={openFilterAlert}
            jobAlertData={jobAlertData}
            isEdit={isEdit}
            editJobId={jobToEdit?.id}
            userJobFilters={userJobFilters}
            handleSuccessfulCompletion={handleSuccessfulCompletion}
          />
        </>
      )}
    </GenericDrawer>
  )
}

export default JobDrawer
