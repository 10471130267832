import React from 'react'
import { Chip, FormControl, Grid } from '@mui/material'
import GenericFieldHeader from '../GenericComponents/GenericFieldHeader'

const JobChipField = ({
  label,
  name,
  options,
  icons,
  required = false,
  value,
  setState,
  tooltipText,
}) => {
  const handleReset = () => {
    setState(name, null) // Reset the specific field to null by passing name as a string key
  }

  const handleChipClick = selectedId => {
    setState(name, selectedId) // Update the specific field with the selected option ID
  }

  return (
    <FormControl component='fieldset' fullWidth>
      <GenericFieldHeader
        name={label}
        field={value}
        optional={!required}
        handleReset={handleReset}
        showClear={Boolean(value)}
        tooltipText={tooltipText}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {options.map(option => (
          <Grid item xs={6} sm={4} md={4} key={option.id}>
            <Chip
              icon={icons[option.id]}
              label={option.name}
              clickable
              color={value === option.id ? 'primary' : 'default'}
              onClick={() => handleChipClick(option.id)}
              variant={value === option.id ? 'filled' : 'outlined'}
              sx={{
                justifyContent: 'center',
                width: '100%',
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  )
}

export default JobChipField
