import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  Grid,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'
import { fetchCreateUserFilter, fetchFilterTypes } from 'src/redux/filters'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { filterDefinitions } from 'components/Filters/filterDefinitions'

const cadenceItems = [
  { id: 1, name: 'Daily' },
  { id: 2, name: 'Weekly' },
  { id: 3, name: 'Monthly' },
]

const DefaultFiltersSetupDialog = ({ open, onClose, onSkip }) => {
  const {
    firstName,
    state,
    candidateType,
    specialties = [],
    subspecialties = [],
    email,
  } = useSelector(state => state.candidate)
  const { userId } = useSelector(state => state.identity)

  const [filterName, setFilterName] = useState('')
  const [alertCadence, setAlertCadence] = useState('')
  const { filterTypes } = useSelector(state => state.filters)
  const [filterCriteria, setFilterCriteria] = useState({
    profession: true,
    specialty: specialties.length > 0, // Set initial value based on presence of specialties
    subspecialty: subspecialties.length > 0, // Set initial value based on presence of subspecialties
    state: true,
  })
  const [queryString, setQueryString] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFilterTypes())

    // Initialize query string with default checked values
    const initQueryString = () => {
      const params = new URLSearchParams()

      if (filterCriteria.profession && candidateType?.id) {
        const filterDefinition = filterDefinitions.find(def => def.name === 'candidateTypes')
        params.set(filterDefinition.queryStringKey, candidateType?.id)
      }
      if (filterCriteria.specialty && specialties[0]?.id) {
        const filterDefinition = filterDefinitions.find(def => def.name === 'specialty')
        params.set(filterDefinition.queryStringKey, specialties[0]?.id)
      }
      if (filterCriteria.subspecialty && subspecialties[0]?.id) {
        const filterDefinition = filterDefinitions.find(def => def.name === 'subspecialty')
        params.set(filterDefinition.queryStringKey, subspecialties[0]?.id)
      }
      if (filterCriteria.state && state) {
        const filterDefinition = filterDefinitions.find(def => def.name === 'state')
        params.set(filterDefinition.queryStringKey, state)
      }

      setQueryString(params.toString()) // Set the initial query string
    }

    initQueryString()
  }, [dispatch, candidateType, specialties, subspecialties, state, filterCriteria])

  // Function to update the query string dynamically when a checkbox is checked
  const updateQueryString = (key, checked, value) => {
    const params = new URLSearchParams(queryString)

    if (checked) {
      params.set(key, value) // Add or update the query parameter if checkbox is checked
    } else {
      params.delete(key) // Remove the parameter if unchecked
    }

    setQueryString(params.toString()) // Update the query string state
  }

  // Handle checkbox changes and update filterCriteria and query string
  const handleFilterChange = event => {
    const { name, checked } = event.target

    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: checked,
    }))

    let filterDefinition
    switch (name) {
      case 'profession':
        filterDefinition = filterDefinitions.find(def => def.name === 'candidateTypes')
        updateQueryString(filterDefinition.queryStringKey, checked, candidateType?.id)
        break
      case 'specialty':
        filterDefinition = filterDefinitions.find(def => def.name === 'specialty')
        updateQueryString(filterDefinition.queryStringKey, checked, specialties[0]?.id)
        break
      case 'subspecialty':
        filterDefinition = filterDefinitions.find(def => def.name === 'subspecialty')
        updateQueryString(filterDefinition.queryStringKey, checked, subspecialties[0]?.id)
        break
      case 'state':
        filterDefinition = filterDefinitions.find(def => def.name === 'state')
        updateQueryString(filterDefinition.queryStringKey, checked, state)
        break
      default:
        break
    }
  }

  // Handle form submission and dispatch the save filter action

  const handleSubmit = () => {
    const filterType = filterTypes.find(type => type.name === 'Job')
    const payload = {
      userId: userId,
      name: filterName,
      queryString,
      filterTypeId: filterType.id,
      ...(alertCadence && alertCadence !== '' && { alertCadence }), // Conditionally include alertCadence
    }

    dispatch(fetchCreateUserFilter(payload, onClose))

    dispatch(
      fetchTrackEvent({
        eventName: `candidate_default_filter_setup_submit`,
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
      }),
    )
  }

  const handleSkip = () => {
    dispatch(
      fetchTrackEvent({
        eventName: `candidate_default_filter_setup_skip`,
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: email,
      }),
    )
    onSkip()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Grid
        container
        justifyContent='center'
        spacing={2}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2, // Reduced padding
          borderRadius: 2,
          aligntext: 'center',
        }}
      >
        {/* Welcome Message */}
        <Grid item xs={12}>
          <Typography variant='h6' component='h2'>
            {`👋 Welcome, ${firstName}!`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption'>
            {`Would you like to set up criteria for email alerts and a quick access saved filter based on your preferences?`}
          </Typography>
        </Grid>

        {/* Setup Filter Name */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Saved Filter Name'
            variant='outlined'
            value={filterName}
            onChange={e => setFilterName(e.target.value)}
            placeholder='e.g., My Ideal Job Filter'
            size='small' // Reduced size for tighter spacing
          />
        </Grid>

        {/* Filter Criteria Checkboxes */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!filterCriteria.profession} // Convert to boolean
                onChange={handleFilterChange}
                name='profession'
              />
            }
            label={`Profession: ${candidateType?.name || ''}`}
          />
        </Grid>

        {/* Only show Specialty if there are specialties available */}
        {specialties.length > 0 && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!filterCriteria.specialty} // Convert to boolean
                  onChange={handleFilterChange}
                  name='specialty'
                />
              }
              label={`Specialty: ${specialties[0]?.name || ''}`}
            />
          </Grid>
        )}

        {/* Only show Subspecialty if there are subspecialties available */}
        {subspecialties.length > 0 && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!filterCriteria.subspecialty} // Convert to boolean
                  onChange={handleFilterChange}
                  name='subspecialty'
                />
              }
              label={`Subspecialty: ${subspecialties[0]?.name || ''}`}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!filterCriteria.state} // Convert to boolean
                onChange={handleFilterChange}
                name='state'
              />
            }
            label={`State: ${state}`}
          />
        </Grid>

        {/* Email Alert Dropdown */}
        <Grid item xs={12}>
          <Typography variant='caption'>
            {'Setup email alerts to get notified of jobs that fit your criteria (optional)'}
          </Typography>
          <FormControl fullWidth size='small'>
            <InputLabel id='alert-cadence-label'>Email Alert Frequency</InputLabel>
            <Select
              labelId='alert-cadence-label'
              id='alert-cadence'
              value={alertCadence}
              label='Email Alert Frequency'
              onChange={e => setAlertCadence(e.target.value)} // Fixed typo here
            >
              {cadenceItems.map(item => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Action Buttons */}
        <Grid container item direction='row' justifyContent='center' spacing={1}>
          <Grid item>
            <Button onClick={handleSkip} size='small'>
              Skip
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit}
              variant='contained'
              color='primary'
              disabled={!filterName} // Disable if filter name is not set
              size='small' // Reduced button size
            >
              Save Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DefaultFiltersSetupDialog
