import React from 'react'
import { MenuItem, TextField } from '@mui/material'

const JobTextField = ({
  label,
  name,
  value,
  showError,
  options = null,
  setState,
  setJobAlertData = null,
  required,
}) => {
  const handleChange = e => {
    const newValue = e.target.value
    setState(name, newValue)
    if (setJobAlertData !== null) {
      // Find the selected option object with both `name` and `label`
      const optionSelected = options
        ? options.find(o => o.id === newValue)
        : { name: newValue, label: newValue }
      setJobAlertData(prevData => ({
        ...prevData, // Keep existing key-value pairs
        [name]: {
          name: optionSelected.id,
          label: label,
          value: { id: optionSelected.id, name: optionSelected.name },
        },
      }))
    }
  }

  return (
    <TextField
      id={name}
      select={!!options && options.length > 0}
      label={`${label}${required ? ' *' : ''}`}
      value={value || ''}
      onChange={handleChange} // Use updated handleChange
      fullWidth
      size='small'
      error={showError && !value}
      helperText={showError && !value ? 'Required' : ''}
    >
      {options && options.length > 0
        ? options.map(o => (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          ))
        : null}
    </TextField>
  )
}

export default JobTextField
